// app/javascript/controllers/budgets_index_tour_controller.js
import BaseTourController from "./base_tour_controller";
import i18n from "../config/i18n";

export default class BudgetsNewTour extends BaseTourController {
  static values = {
    tourKey: String,
    defaultCurrency: String,
  };

  // Define specific steps for the 'budgets new' tour
  steps() {
    return [
      {
        title: i18n.t("tour.new_budget.title"),
        text: i18n.t("tour.new_budget.amount.text", {
          currency: this.defaultCurrencyValue,
        }),
        attachTo: {
          element: "input[name='budget[amount]']",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.next"),
          },
        ],
      },
      {
        title: i18n.t("tour.new_budget.title"),
        text: i18n.t("tour.new_budget.categories.text"),
        attachTo: {
          element: "div.accounts_and_categories",
          on: "top",
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            text: i18n.t("tour.buttons.previous"),
            secondary: true,
          },
          {
            action() {
              return this.next();
            },
            text: i18n.t("tour.buttons.finish"),
          },
        ],
      },
    ];
  }
}
