import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["form"];

  async save(event) {
    event.preventDefault();
    const formElement = event.currentTarget;

    try {
      const response = await fetch(formElement.action, {
        method: "PATCH",
        headers: {
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
          Accept: "application/json",
        },
        body: new FormData(formElement),
      });

      if (!response.ok) {
        throw new Error(await response.text());
      }

      const data = await response.json();

      // Update specific elements if needed based on response
      if (data.success) {
        this.notifySuccess("Changes saved successfully");
      }
    } catch (error) {
      console.error("Error saving change:", error);
      this.notifyError("Failed to save changes");
    }
  }

  notifySuccess(message) {
    // Implement your preferred notification method
    // For example, using Bulma's notification component
    this.dispatch("notification", {
      type: "success",
      message: message,
    });
  }

  notifyError(message) {
    this.dispatch("notification", {
      type: "error",
      message: message,
    });
  }
}
