import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

/* This is your ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  /* Application-wide lifecycle methods
   *
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Example#demo"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  beforeReflex(element, reflex, noop, reflexId) {
    // document.body.classList.add('wait')
    this.destroySelect2s();
    if (reflex.includes("Reflex#search")) {
      this.toggleSearchSpinner(element, true);
    }
    if (reflex.includes("Reflex#paginate")) {
      this.togglePaginateSpinner(element, true);
    }
  }

  reflexSuccess(element, reflex, noop, reflexId) {
    // show success message
  }

  reflexError(element, reflex, error, reflexId) {
    // show error message
  }

  reflexHalted(element, reflex, error, reflexId) {
    // handle aborted Reflex action
  }

  afterReflex(element, reflex, noop, reflexId) {
    // document.body.classList.remove('wait')
    this.initializeSelect2s();
    if (reflex.includes("Reflex#search")) {
      this.toggleSearchSpinner(element, false);
    }

    // No need to revert, the page will reload the pagination element
    // if (reflex.includes("Reflex#paginate")) {
    //   this.togglePaginateSpinner(element, false);
    // }
  }

  finalizeReflex(element, reflex, noop, reflexId) {
    // all operations have completed, animation etc is now safe
  }

  initializeSelect2s() {
    document
      .querySelectorAll("select[data-controller='select2']")
      .forEach((element) => {
        this.initializeSelect2(element);
      });
  }

  destroySelect2s() {
    document
      .querySelectorAll("select[data-controller='select2']")
      .forEach((element) => {
        this.destroySelect2(element);
      });
  }

  destroySelect2(element) {
    if ($(element).data("select2")) {
      $(element).select2("destroy");
    }
  }

  initializeSelect2(element) {
    if (!$(element).data("select2")) {
      $(element).select2({
        theme: $(element).data("theme") || "default",
        minimumResultsForSearch: $(element).data("searchable") ? 0 : Infinity,
      });

      // Handle class changes if data-onchange-class is present
      if (element.dataset.onchangeClass) {
        const [defaultClass, alternateClass] =
          element.dataset.onchangeClass.split(":");

        $(element).on("change", function () {
          const select2Container = $(this).next(".select2-container");

          select2Container.removeClass(defaultClass);
          select2Container.removeClass(alternateClass);
          select2Container.addClass(alternateClass);
        });
      }

      $(element).on("select2:select select2:unselect", (event) => {
        event.stopPropagation();
        const nativeEvent = new Event("change", { bubbles: true });
        event.target.dispatchEvent(nativeEvent);
      });

      if (element.dataset.opacity) {
        $(element).on("select2:opening", () => {
          const table = element.closest("table");
          const currentRow = element.closest("tr");

          if (table && currentRow) {
            table.style.position = "relative";
            const overlay = document.createElement("div");
            overlay.id = `select2-overlay-${element.id}`;

            // Create a clip path to exclude the current row
            const tableRect = table.getBoundingClientRect();
            const rowRect = currentRow.getBoundingClientRect();
            const clipTop =
              ((rowRect.top - tableRect.top) / tableRect.height) * 100;
            const clipHeight = (rowRect.height / tableRect.height) * 100;

            overlay.style.cssText = `
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.2);
              z-index: 1050;
              clip-path: polygon(
                0% 0%,
                100% 0%,
                100% ${clipTop}%,
                0% ${clipTop}%,
                0% ${clipTop + clipHeight}%,
                100% ${clipTop + clipHeight}%,
                100% 100%,
                0% 100%
              );
            `;
            table.appendChild(overlay);
          }
        });

        $(element).on("select2:closing", () => {
          const overlay = document.getElementById(
            `select2-overlay-${element.id}`
          );
          if (overlay) {
            overlay.remove();
          }
        });
      }
    }
  }

  toggleSearchSpinner(element, show) {
    const searchButton =
      element.closest(".button") ||
      element.parentElement.nextElementSibling.children[0];

    if (searchButton) {
      this.toggleIcon(searchButton, show);
    }
  }

  togglePaginateSpinner(element, show) {
    const paginateButton = element;

    if (paginateButton) {
      this.toggleIcon(paginateButton, show);
    }
  }

  toggleIcon(button, show) {
    const icons = button.querySelector("span.icon");
    const icon = icons.children[0];
    const spinner = icons.children[1];
    if (show) {
      icon.classList.add("is-hidden");
      spinner.classList.remove("is-hidden");
    } else {
      icon.classList.remove("is-hidden");
      spinner.classList.add("is-hidden");
    }
  }
}
