// Core libraries
require("@rails/ujs").start();
require("@rails/actioncable");
require("@rails/activestorage").start();
require("channels");
require.context("../application/images", true);

// Font Awesome and Stylesheets
import "@fortawesome/fontawesome-free/js/all";
import "../application/stylesheets/application";

import "animate.css";
import "config";
import "controllers";
import "channels";
import 'select2'
import 'select2/dist/css/select2.css'
