import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["searchInput", "searchButton"];

  connect() {
    this.sync();
  }

  sync() {
    if (!this.searchInputTarget || !this.searchButtonTarget) return;
    const value = this.searchInputTarget.value;
    this.searchButtonTarget.dataset.searchValue = value;
  }

  onKeyUp(event) {
    if (event.key === "Enter") {
      this.searchButtonTarget.click();
    }
  }
}
