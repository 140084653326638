import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    this.initializeSelect2(this.element);
  }

  disconnect() {
    this.destroySelect2(this.element);
  }
}
